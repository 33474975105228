<!-- @format -->

<template>
  <div>
    <div class="lg:mr-2 lg:ml-8">Besuche uns auf</div>
    <div>
      <a
        class="mr-1 fluid-type-nfpsocial"
        href="https://www.facebook.com/NFPKino"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'facebook-square']" />
      </a>
      <a
        class="mr-1 fluid-type-nfpsocial"
        href="https://www.youtube.com/channel/UCNWqErBUP3ve1vfnf-gTaDA"
        target="_blank"
      >
        <font-awesome-icon :icon="['fab', 'youtube-square']" />
      </a>
    </div>
  </div>
</template>

<script>
  export default {}
</script>
